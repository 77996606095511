<template>
        <base-page>
        <titlu-pagina Titlu="Clienti" @on_add_clicked="show_dialog" :AdaugaVisible="$has_right('adaugare-client')" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
            <el-col :md='4'>
                <el-form-item label='Nume' >
                    <el-input v-model='Filters.Nume' />
                </el-form-item>
            </el-col>
            
            <el-col :md='4'>
                <el-form-item label='Email' >
                    <el-input v-model='Filters.Email' />
                </el-form-item>
            </el-col>
            <el-col :md='4'>
                <el-form-item label='Telefon' >
                    <el-input v-model='Filters.Telefon' />
                </el-form-item>
            </el-col>            
            <el-col :md='4'>
                <el-form-item label='Nume firma' >
                    <el-input v-model='Filters.NumeFirma' />
                </el-form-item>
            </el-col>
            <el-col :md='4'>
                <el-form-item label='Cui' >
                    <el-input v-model='Filters.Cui' />
                </el-form-item>
            </el-col>                                  
            <el-col :md='24' >
                <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                <el-button  @click='reset'> Reset </el-button>
            </el-col>

        </el-row>
                </el-form>
            </div>
        </el-card>

<el-table ref="multipleTable" :data="Results" @selection-change="handleSelectionChange">  
    <el-table-column type="selection" width="50"></el-table-column>   
    <el-table-column v-if="multipleSelection.length > 0" width="55">
        <template slot="header" >
            <el-tooltip content="Stergere selectii" placement="top">
                <el-button size='mini' type='danger' icon='el-icon-delete' plain @click="deschideDialogStergere"> </el-button>
            </el-tooltip>
        </template>
    </el-table-column>       
    <el-table-column prop='Nume' label='Nume'></el-table-column>
    <el-table-column width="300px" prop='Masini' label='Masini'></el-table-column>
    <el-table-column prop='Email' label='Email'></el-table-column>
    <el-table-column prop='Telefon' label='Telefon'></el-table-column>
    <el-table-column prop='NumeFirma' label='Nume firma'></el-table-column>
    <el-table-column prop='Cui' label='Cui'></el-table-column>
    <el-table-column fixed="right" label="Actiuni" width="200px" >
        <template slot-scope="scope" >
            <el-tooltip v-if="$has_right('modificare-client')" content="Modificare">
                <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
            </el-tooltip>

            <el-tooltip v-if="$has_right('stergere-client')" content="Sterge" >
                <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
            </el-tooltip>
        </template>
    </el-table-column>
</el-table>
<el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
<Clienti-dialog ref='dlg' @save="refresh_info" />
<el-dialog
    width="30%"
    title="Stergere clienti"
    :visible.sync="innerVisible"
    >
    <ul>
        <li v-for="item in multipleSelection" :key="item.Id">{{item.Nume}} </li>
    </ul>
    <span slot="footer" class="dialog-footer" >
        <el-button @click="innerVisible = false" > Renunta  </el-button>
        <el-button type="primary" @click="stergereClienti" > Confirma </el-button>
    </span>
</el-dialog>

</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Clienti_dialog from '@/pages/clienti/Clienti_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "clienti",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Clienti-dialog': Clienti_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { },                            
            Filters: {
                Nume: '' , Email: '' , Telefon: '' , DataNasterii: '' , NumeFirma: '' , Cui: '' ,},
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            multipleSelection: [],
            innerVisible: false
        }
    },
    methods: {

        async get_info(){
            if (!this.$has_right('vizualizare-clienti')){
                this.$router.push('/no-rights')
                return
            }
            
            var response = await this.post("clienti/get_info" );
            this.refresh_info();
        },

        async refresh_info(){
            this.innerVisible   = false
            var response        = await this.post("clienti/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('clienti');
        },
        reset(){
            this.Filters = {
                Nume: '' , Email: '' , Telefon: '' , DataNasterii: '' , NumeFirma: '' , Cui: '' };
            this.refresh_info();
        },
        async delete_item( item ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("clienti/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },
        show_dialog(id){
            // console.log(this.$refs.dlg.show_me());
            
            this.$refs.dlg.show_me(id);
        },
        handleSelectionChange(rows){
            this.multipleSelection = rows
        },
        async stergereClienti(){
            var response = await this.post('clienti/stergere_multipla', {Clienti: this.multipleSelection})
            this.$message({type: 'success', message: response.mesaj});
            this.refresh_info()
        },
        
        deschideDialogStergere(){
            this.innerVisible = true
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();        
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
