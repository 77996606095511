import { render, staticRenderFns } from "./Clienti_list.vue?vue&type=template&id=3be12c66&scoped=true&"
import script from "./Clienti_list.vue?vue&type=script&lang=js&"
export * from "./Clienti_list.vue?vue&type=script&lang=js&"
import style0 from "./Clienti_list.vue?vue&type=style&index=0&id=3be12c66&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be12c66",
  null
  
)

export default component.exports